<template>
  <div
    id="content"
    class="h-full min-h-screen w-full flex justify-center items-center"
    :class="[
      useRoute().name !== useRoutesNames().posLockscreen
        ? 'bg-primary-grey-background'
        : 'bg-accent-color',
    ]"
  >
    <slot />
  </div>
</template>

<script lang="ts" setup></script>
